<template>
  <loading-dialog :loading="$apollo.loading">
    <base-page-layout :title="`${getSafe(() => adminAccount.name)}`">
      <v-tabs v-model="tab" grow class="mb-5">
        <v-tab to="geral">Gerais</v-tab>
        <v-tab to="flags">Flags</v-tab>
        <v-tab to="lojas">Lojas</v-tab>
        <v-tab to="importacao">Importação</v-tab>
        <v-tab to="processamento">Processamento</v-tab>
        <v-tab to="cashback">Cashback</v-tab>
        <v-tab to="stage">Stage</v-tab>
        <v-tab to="canais-marketing">Canais de Marketing</v-tab>
        <v-tab to="geolocalizacao">Geo-Localização</v-tab>
        <v-tab to="dag">DAG/Locks</v-tab>
        <v-tab to="hotsite">Configurações do Hotsite</v-tab>
        <v-tab to="retailer-flags">Bandeiras</v-tab>
      </v-tabs>
      <router-view />
    </base-page-layout>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue')
  },
  data: () => ({
    tab: 0,
    accountId: ''
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      result({ data }) {
        if (data?.adminAccount?.is_franchise) {
          localStorage.setItem('is_franchise', true)
        } else {
          localStorage.setItem('is_franchise', false)
        }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  }
}
</script>
